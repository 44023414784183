import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import { auth } from '../../../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth"; // Import sendPasswordResetEmail
import CoverLayout from "layouts/authentication/components/BasicLayout";
import CircularProgress from "@mui/material/CircularProgress";
import bgSignIn from "assets/images/signInImage.png";
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import bgDash from "assets/images/body-background.png"; // Replace with your background image
import bgDash2 from "assets/images/billing-background-balance.png"; // Replace with your background image
import { Height } from "@mui/icons-material";
import longlogo from "assets/images/logos/longlogo.png";
import longlogowhite from "assets/images/logos/longlogowhite.png";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { FaPhotoVideo } from "react-icons/fa";
import { FaTools } from "react-icons/fa";
import { FaChrome } from "react-icons/fa";
import { FaProjectDiagram } from "react-icons/fa";
import { MdDesignServices } from "react-icons/md";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import RcSlider from 'rc-slider'; // Correct import statement
import 'rc-slider/assets/index.css'; // Import the default styles
import { Box, Typography } from '@mui/material'; // Ensure you're using MUI components

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import laptop1 from "assets/images/laptops/1.png";
import laptop2 from "assets/images/laptops/2.png";
import laptop3 from "assets/images/laptops/3.png";
import laptop4 from "assets/images/laptops/4.png";
import { fontGrid } from "@mui/material/styles/cssUtils";

import proof1 from "assets/images/flippaproof/1.png";
import proof2 from "assets/images/flippaproof/2.png";
import proof3 from "assets/images/flippaproof/3.png";
import proof4 from "assets/images/flippaproof/4.png";
import proof5 from "assets/images/flippaproof/5.png";
import proof6 from "assets/images/flippaproof/6.png";
import proof7 from "assets/images/flippaproof/7.png";
import proof8 from "assets/images/flippaproof/8.png";
import proof9 from "assets/images/flippaproof/9.png";
import proof10 from "assets/images/flippaproof/10.png";

import { FaAppStoreIos } from "react-icons/fa";
import { PiPresentationChartFill } from "react-icons/pi";
import { FaVideo } from "react-icons/fa";
import { FaBrain } from "react-icons/fa";
import { IoIosSchool } from "react-icons/io";

import { useMediaQuery } from '@mui/material'; // Import useMediaQuery
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // carousel styles
import bgDash3 from "assets/images/bg3.svg"; // Replace with your background image
import bgDash4 from "assets/images/bg4.svg"; // Replace with your background image
import longlogoblack from "assets/images/codegrowlong-black.png"; // Replace with your background image
import boxscreen from "assets/images/boxscreen.png"; // Replace with your background image
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Import Material-UI icon (if using MUI)
import { FaArrowRightLong } from "react-icons/fa6";

import { LiaArrowRightSolid } from "react-icons/lia";

import box1 from "assets/images/box1.png"; // Replace with your background image
import box2 from "assets/images/box2.png"; // Replace with your background imageminicolourbg.svg
import minicolourbg from "assets/images/minicolourbg.svg"; // Replace with your background image
import ipad2 from "assets/images/ipad2.png"; // Replace with your background image
import redbox1 from "assets/images/redbox1.png"; // Replace with your background image
import redbox2 from "assets/images/redbox2.png"; // Replace with your background image
import highipad from "assets/images/highipad.gif"; // Replace with your background image
import ai1 from "assets/images/ai1.png"; // Replace with your background image
import ai2 from "assets/images/ai2.png"; // Replace with your background image
import ai3 from "assets/images/ai3.png"; // Replace with your background image
import macbook from "assets/images/macbook.png"; // Replace with your background image

import monthly from "assets/images/monthly.svg"; // Replace with your background image
import yearly from "assets/images/yearly.svg"; // Replace with your background image
import ipadframe from "assets/images/ipadframe.png"; // Replace with your background image

function SignIn() {
  const navigate = useNavigate();
  const [appsPerMonth, setAppsPerMonth] = useState(2);
  const [dailyRevenue, setDailyRevenue] = useState(10);
  const [flippaSalePrice, setFlippaSalePrice] = useState(1500);
  const monthlyRevenuePerApp = dailyRevenue * 30; // 30 days in a month
  const yearlyRevenuePerApp = monthlyRevenuePerApp * 12;
  const totalRevenuePerMonth = (appsPerMonth * 12) * monthlyRevenuePerApp;
  const totalRevenuePerYear = totalRevenuePerMonth * 12;
  const totalFlippaRevenue = (appsPerMonth * 12) * flippaSalePrice;
  const totalCombinedRevenue = totalRevenuePerYear + totalFlippaRevenue;
  const totalCombinedMonthly = totalCombinedRevenue / 12;
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed
  const [openQuestion, setOpenQuestion] = useState(null);
  const [isYearly, setIsYearly] = useState(true);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqData = [
    {
      question: "What is included in the CodeGrow subscription?",
      answer:
        "The subscription includes unlimited access to our AI-powered app development tools, fully customizable app templates, video tutorials, pre-built Firebase and AdMob integrations, and more."
    },
    {
      question: "What AI features are included?",
      answer:
        "CodeGrow's AI tools can generate app designs, suggest code improvements, debug issues, and even create fully functional components to save you time and effort."
    },
    {
      question: "How many apps can I create with CodeGrow?",
      answer:
        "You can create an unlimited number of apps with your subscription. There's no cap on creativity or output!"
    },
    {
      question: "What programming technologies are used in these apps?",
      answer:
        "CodeGrow apps are built with Flutter for cross-platform compatibility and leverage Firebase for back-end services. SQLite is also available for local storage."
    },
    {
      question: "Are there templates available for specific types of apps?",
      answer:
        "Yes, CodeGrow provides a variety of templates for apps such as games, productivity tools, social media integrations, and more. All templates are fully customizable."
    },
    {
      question: "Can I access CodeGrow on multiple devices?",
      answer:
        "Absolutely! CodeGrow is fully responsive and works seamlessly on desktops, tablets, and smartphones, allowing you to build apps from anywhere."
    },
    {
      question: "How do I get started with CodeGrow?",
      answer:
        "Once you sign up, you'll receive immediate access to the platform. Start by exploring the tutorials and templates, and you'll be creating your first app in no time."
    },
    {
      question: "Can I cancel my subscription at any time?",
      answer:
        "Yes, you can cancel your subscription anytime by logging into your account and navigating to the billing section."
    },
    {
      question: "Does CodeGrow offer real-time support?",
      answer:
        "Yes, our support team is available to assist you with any questions or issues you may encounter while using CodeGrow."
    }
  ];


  const featureItems = [
    {
      icon: <FaPhotoVideo style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "Comprehensive Dashboard",
      description: "Provides an all-in-one overview of projects, monthly revenue, performance metrics, and key insights",
    },
    {
      icon: <FaVideo style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "1:1 Coaching Calls",
      description: "Offers personalized, expert guidance to help improve coding skills, business strategies, and overall app development success.",
    },
    {
      icon: <IoIosSchool style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "Resource Center",
      description: "Provides access to templates, guides, video courses, and other valuable materials to support full programming operations",
    },
    {
      icon: <FaTools style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "Project Management Portal",
      description: "Provides comprehensive tracking of project insights, including version control, user engagement, revenue analytics, Flippa listings, ensuring all critical data is centralized.",
    },
    {
      icon: <FaBrain style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "AI Development Tools",
      description: "Equips developers with advanced AI-driven resources to streamline coding, automate tasks, and optimize app performance.",
    },
    {
      icon: <FaProjectDiagram style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "Ready To Launch Apps",
      description: "Provides pre-built, fully functional app templates, allowing users to quickly deploy and customize apps with minimal effort.",
    },
    {
      icon: <FaChrome style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "AI Chrome Extensions",
      description: "Features powerful Chrome extensions that integrate AI capabilities to enhance productivity and streamline online development workflows.",
    },
    {
      icon: <PiPresentationChartFill style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "Sales Guides And Pitch Decks",
      description: "Includes expertly crafted guides and templates for creating compelling sales pitches and investor-ready presentations.",
    },
    {
      icon: <FaAppStoreIos style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "App Store Development Tools",
      description: "Offers a suite of tools designed to simplify the app store submission process, from app optimization to marketing strategies.",
    },
  ];

  const handleDownload = () => {
    const pdfUrl = "https://firebasestorage.googleapis.com/v0/b/codegrow-e0300.appspot.com/o/The%20Complete%20Guide%20to%20Building%20and%20Monetizing%20Simple%20iOS%20Apps%20with%20AI.pdf?alt=media&token=a779f081-5d00-48aa-acde-042d2dcec962"; // Firebase URL

    // Open the PDF in a new browser tab
    window.open(pdfUrl, "_blank");
  };

  function navigateToVideoSection() {
    const element = document.getElementById("demoSection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }
  
  return (
    <><CoverLayout >

      {/* top section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          backgroundColor: "white",
          minHeight: "100vh",
          marginBottom: '-20px',
          backgroundImage: `url(${bgDash4})`, // Background image for the landing page
          backgroundSize: "cover", // Ensures the image covers the entire area
          backgroundPosition: "center", // Centers the image
          backgroundRepeat: "no-repeat", // Prevents the image from repeating
          padding: "0", // Remove padding to avoid affecting width
        }}
      >

        {/* top menu*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={isMobile ? "100%" : "90%"}
          // maxWidth="800px"
          marginTop="0px"
          padding="20px"

          borderRadius={borders.borderRadius.md}
        >
          {/* Logo on the Left */}
          <VuiBox display="flex" alignItems="center">
            <img
              src={longlogoblack} // Remove the `${}`
              alt="Logo"
              style={{ height: isMobile ? "17vw" : "10vw", marginBottom: '-20px' }}
            />

          </VuiBox>

          {/* Buttons on the Right */}
          <VuiBox display="flex" gap="20px" style={{ marginBottom: '-20px' }}
          >
            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigate("/authentication/sign-in")}
              style={{
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px'
              }}
            >
              Log in
            </VuiButton>

            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigate("/authentication/sign-in")}
              style={{
                backgroundColor: "#404040",
                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px',
                display: isMobile ? "none" : "block",
              }}
            >
              Get Started
            </VuiButton>
            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={navigateToVideoSection}
              style={{
                backgroundColor: "white",
                color: "#fe3737",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px'
              }}
            >
              See Demo
            </VuiButton>

          </VuiBox>
        </VuiBox>
        {/* top menu end */}


        <VuiBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width={isMobile ? "100%" : "90%"}
          // maxWidth="800px"
          marginTop="0px"
          padding="20px"


          borderRadius={borders.borderRadius.md}
        >


          <VuiTypography
            style={{
              fontSize: isMobile ? "7.5vw" : "5.5vw", // Adjusts according to the viewport width
              fontWeight: "bold",
              color: "white",
              marginBottom: isMobile ? "0px" : "-20px",
              textAlign: "center", // Centers the text
            }}
          >
            Generate, Build & Launch
          </VuiTypography>
          <VuiTypography
            style={{
              fontSize: isMobile ? "7.5vw" : "5.5vw", // Adjusts according to the viewport width
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "15px",
              textAlign: "center", // Centers the text
            }}
          >
            Your Perfect iOS App.
          </VuiTypography>

          <VuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width={isMobile ? "100%" : "65%"}
            // maxWidth="800px"
            marginTop="0px"
            padding="20px"

            borderRadius={borders.borderRadius.md}
          >

            <VuiTypography style={{ textAlign: "center", fontSize: isMobile ? "4.5vw" : "1.5vw", fontWeight: "bold", color: "white", marginBottom: "20px" }}>
              Effortlessly transforms ideas into apps with AI-Powered Development, Customization Tools, Monetization Integration, and Scalable Growth Features.
            </VuiTypography>
          </VuiBox>


          <VuiButton
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => navigate("/authentication/sign-in")}
            style={{
              backgroundColor: "#fe3737",
              color: "#fff",
              fontSize: isMobile ? "4.5vw" : "1.7vw",
              padding: "12px 45px",
              borderRadius: '55px',
              width: isMobile ? "70%" : "25%",
            }}
          >
            Get Started
          </VuiButton>
        </VuiBox>


        {/* triple section menu*/}
        <VuiBox
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"

          style={{
            width: isMobile ? "70%" : "50%"
          }}
          // maxWidth="800px"
          marginTop="0px"
          padding="20px"
          background={rgba(palette.info.main, 0.8)}
          borderRadius={borders.borderRadius.md}
          flexDirection={isMobile ? "column" : "row"}
        >


          <VuiTypography
            style={{
              fontSize: "48px",
              color: "#fe3737",
              marginBottom: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <IoMdCheckmarkCircle style={{ marginRight: "10px", fontSize: isMobile ? "5vw" : "2.7vw" }} /> {/* Icon size and margin */}
            <b style={{ fontSize: isMobile ? "4.5vw" : "1.7vw", color: 'white' }}>Launch in Minutes</b>
          </VuiTypography>

          <VuiTypography
            style={{
              fontSize: "48px",
              color: "#fe3737",
              marginBottom: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <IoMdCheckmarkCircle style={{ marginRight: "10px", fontSize: isMobile ? "5vw" : "2.7vw" }} /> {/* Icon size and margin */}
            <b style={{ fontSize: isMobile ? "4.5vw" : "1.7vw", color: 'white' }}>Source Code Ready</b>
          </VuiTypography>



        </VuiBox>
        {/* triple section end */}

        {/* image Section */}

        <VuiBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width={isMobile ? "100%" : "90%"}
          marginTop={isMobile ? "-40px" : "0px"}
          padding="20px"
          borderRadius={borders.borderRadius.md}
        >
          <img
            src={box2}
            alt="Centered Image"
            style={{
              width: isMobile ? "80vw" : "50vw",
              marginBottom: '0px',
              objectFit: "contain", // Ensures the image scales nicely within the specified width
              margin: "0 auto", // Centers the image horizontally
            }}
          />
        </VuiBox>

        {/* image section end */}
      </VuiBox>
      {/* top section end */}

      {/* second section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          background: "white", // Fading effect

          marginTop: '0px',

          padding: "0", // Remove padding to avoid affecting width
        }}
      >


        {/* top menu*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "row"}
          alignItems={isMobile ? "start" : "center"}
          width={isMobile ? "100%" : "90%"}
          // maxWidth="800px"
          marginTop="-70px"
          padding="20px"
          style={{
            background: "linear-gradient(to top, white 60%, transparent 100%)", // Fading effect
          }}
          borderRadius={borders.borderRadius.md}
        >
          <VuiBox

            width={isMobile ? "100%" : "48%"}
          >
            <VuiTypography style={{ textAlign: "start", fontSize: isMobile ? "4.5vw" : "2.8vw", fontWeight: "bold", color: "black", marginBottom: "20px" }}>
              AI-Driven Tools <bold style={{ color: '#69696a' }}> Powering App Store Success</bold>
            </VuiTypography>
          </VuiBox>

          {/* Buttons on the Right */}
          <VuiBox display="flex" gap="20px" style={{ marginBottom: '-20px' }}
          >

            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigate("/authentication/sign-in")}
              style={{
                backgroundColor: 'transparent',
                border: " 2px solid #1079fb",
                color: " #1079fb",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px'
              }}
            >
              Get Started
              <FaArrowRightLong
                style={{ marginLeft: "8px" }}

              />  </VuiButton>

          </VuiBox>
        </VuiBox>
        {/* top menu end */}
      </VuiBox>

      {/* second section end */}

      {/* third section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          background: "linear-gradient(to top, #F7F8FB 95%, white 100%)", // Fading effect
          minHeight: "100vh",
          marginTop: '0px',

          padding: "0", // Remove padding to avoid affecting width
        }}
      >


        {/* first white bubble*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "row"}
          alignItems="center"
          width={isMobile ? "100%" : "90%"}

          // maxWidth="800px"
          marginTop="5%"
          padding="40px"
          style={{
            background: "white", // Fading effect
          }}
          borderRadius="25px"
        >
          <VuiBox

            width={isMobile ? "100%" : "48%"}
          >
            <img
              src={redbox1}
              alt="Centered Image"
              style={{
                marginTop: isMobile ? "10%" : "0px",
                borderRadius: "15px",
                width: isMobile ? "100%" : "40vw",
                objectFit: "contain", // Ensures the image scales nicely within the specified width
                margin: "0 auto", // Centers the image horizontally
                marginBottom: isMobile ? "5%" : "0px",

              }}
            />
          </VuiBox>

          {/* Buttons on the Right */}
          <VuiBox
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            alignItems={isMobile ? "start" : "start"}
            height={isMobile ? "100%" : "32vw"}
            // maxWidth="800px"
            width={isMobile ? "100%" : "50%"}
            padding="20px"
            style={{

              background: "linear-gradient(to top, white 60%, transparent 100%)", // Fading effect
            }}
            borderRadius={borders.borderRadius.md}
          >


            <div>
              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "6.5vw" : "3.8vw",
                  fontWeight: "bold",
                  color: "black",
                  marginBottom: "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                Infinitely Scale Your Portfolio with
              </VuiTypography>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "6.5vw" : "3.4vw",
                  fontWeight: "bold",
                  color: "#FE3737",
                  marginBottom: isMobile ? "10%" : "0px",

                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                Unlimited iOS Apps
              </VuiTypography>

            </div>

            <div>

              <VuiTypography
                style={{
                  textAlign: isMobile ? "start" : "start",
                  fontSize: isMobile ? "4.5vw" : "1.6vw",
                  fontWeight: "bold",
                  color: "#69696a",
                  marginBottom: isMobile ? "10%" : "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                <bold style={{ color: 'black' }}>Create, customize, and launch unlimited iOS apps with ease —</bold> empowering you to scale your app portfolio faster than ever.
              </VuiTypography>



            </div>

            <div>
              <VuiButton
                aligncontent="start"
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => navigate("/authentication/sign-in")}
                style={{
                  backgroundColor: 'transparent',
                  border: " 2px solid #FE3737",
                  color: " #FE3737",
                  fontSize: isMobile ? "3.5vw" : "1.5vw",
                  padding: "10px 20px",
                  borderRadius: '55px',

                }}
              >
                Get Started
              </VuiButton>
            </div>

          </VuiBox>
        </VuiBox>


        {/* first white bubble end */}


        {/* second white bubble*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "row"}
          alignItems="center"
          width={isMobile ? "100%" : "90%"}

          // maxWidth="800px"
          marginTop="5%"
          padding="40px"
          style={{
            background: "white", // Fading effect
          }}
          borderRadius="25px"
        >
          <VuiBox
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            alignItems={isMobile ? "start" : "start"}
            height={isMobile ? "100%" : "32vw"}
            // maxWidth="800px"
            width={isMobile ? "100%" : "50%"}
            padding="20px"
            style={{
              background: "linear-gradient(to top, white 60%, transparent 100%)", // Fading effect
            }}
            borderRadius={borders.borderRadius.md}
          >


            <div>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "6.5vw" : "3.8vw",
                  fontWeight: "bold",
                  color: "black",
                  marginBottom: "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                Reach the App Store with
              </VuiTypography>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "6.5vw" : "3.4vw",
                  fontWeight: "bold",
                  color: "#FE3737",
                  marginBottom: isMobile ? "10%" : "0px",

                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                Seamless Deployment
              </VuiTypography>

            </div>

            <div>

              <VuiTypography
                style={{
                  textAlign: isMobile ? "start" : "start",
                  fontSize: isMobile ? "4.5vw" : "1.6vw",
                  fontWeight: "bold",
                  color: "#69696a",
                  marginBottom: isMobile ? "10%" : "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >

                <bold style={{ color: 'black' }}> Effortlessly launch and distribute your iOS apps to the App Store,</bold> ensuring smooth delivery and maximum visibility.
              </VuiTypography>



            </div>

            <div>
              <VuiButton
                aligncontent="start"
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => navigate("/authentication/sign-in")}
                style={{
                  backgroundColor: 'transparent',
                  border: " 2px solid #FE3737",
                  color: " #FE3737",
                  fontSize: isMobile ? "3.5vw" : "1.5vw",
                  padding: "10px 20px",
                  borderRadius: '55px',

                }}
              >
                Get Started
              </VuiButton>
            </div>

          </VuiBox>
          <VuiBox

            width={isMobile ? "100%" : "48%"}
          >
            <img
              src={redbox2}
              alt="Centered Image"
              style={{
                marginTop: isMobile ? "10%" : "0px",
                borderRadius: "15px",
                width: isMobile ? "100%" : "40vw",
                objectFit: "contain", // Ensures the image scales nicely within the specified width
                margin: "0 auto", // Centers the image horizontally
              }}
            />
          </VuiBox>

          {/* Buttons on the Right */}

        </VuiBox>
        {/* second white bubble end */}


        {/* third white bubble*/}
        <VuiBox

          width={isMobile ? "85%" : "90%"}
          // maxWidth="800px"
          marginTop="5%"
          height={"isMobile ? 100vw : 85vw"}
          padding="40px"
          style={{
            backgroundImage: `url(${minicolourbg})`, // Background image for the landing page

            backgroundSize: "cover", // Ensures the image covers the entire area
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
          }}
          borderRadius="25px"
        >
          <VuiBox
            display="flex"
            flexDirection="column"

            justifyContent={isMobile ? "center" : "space-between"}
            alignItems="center"
            marginLeft={isMobile ? "0px" : '15%'}
            width={isMobile ? "100%" : "70%"}
            // maxWidth="800px"

            height=" 100%"
            padding="0px"

            borderRadius="25px"
          >


            <VuiTypography
              style={{
                textAlign: "center",
                fontSize: isMobile ? "6.5vw" : "3.8vw",
                fontWeight: "bold",
                color: "white",
                marginTop: "15px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
            >
              The World’s First AI-Powered Automated <bold style={{
                color: "#FE3737",
              }}> IOS App Builder</bold>
            </VuiTypography>
            <br />

            <VuiTypography
              style={{
                textAlign: "center",
                fontSize: isMobile ? "4.8vw" : "1.6vw",
                fontWeight: "bold",
                color: "#a1a1a1",
                marginBottom: isMobile ? "20px" : "0px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
            >

              <bold style={{ color: 'white' }}> Build fully customized iOS apps in minutes -</bold> streamline development, integrate monetization, and scale effortlessly with AI-powered automation.
            </VuiTypography>
            <br />

            <VuiButton
              aligncontent="center"
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigate("/authentication/sign-in")}
              style={{
                backgroundColor: '#1079FB',
                width: isMobile ? "50%" : "25%",
                color: " white",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px',
                marginBottom: isMobile ? "20px" : "0px"
              }}
            >
              Get Started
            </VuiButton>
            <br />

            {/* <video
              src="https://firebasestorage.googleapis.com/v0/b/codegrow-e0300.appspot.com/o/ipad-video-high-quality.mp4?alt=media&token=1fc65fdc-aecb-45e9-b7ae-93ed14cfb471" // Replace with your Firebase video URL
              alt="Centered Video"
              autoPlay
              loop
              muted
              playsInline
              style={{
                borderRadius: "15px",
                border: isMobile ? "5px solid white" : "28px solid white",
                width: isMobile ? "80vw" : "70vw",
                objectFit: "contain", // Ensures the video scales nicely within the specified width
                marginBottom: isMobile ? "0px" : "20px", // Centers the image horizontally
              }}
            >

            </video> */}
        <iframe
  id="demoSection"
  src="https://www.youtube.com/embed/3Btxi2OmrCU?autoplay=1&mute=1&loop=1&playlist=3Btxi2OmrCU&controls=0&showinfo=0&modestbranding=1&rel=0&iv_load_policy=3" 
  title="Centered YouTube Video"
  allow="autoplay; loop; encrypted-media; fullscreen"
  style={{
    borderRadius: "15px",
    border: isMobile ? "5px solid white" : "28px solid white",
    width: isMobile ? "75vw" : "65vw",
    height: isMobile ? "52vw" : "47vw", // Maintain a 16:9 aspect ratio for the video
    objectFit: "contain",
    marginBottom: isMobile ? "0px" : "20px",
    pointerEvents: "none", // Disables user interaction on the iframe
  }}
  frameBorder="0"
/>



          </VuiBox>


        </VuiBox>
        {/* third white bubble end */}


        <VuiTypography
          style={{
            fontSize: isMobile ? "5.8vw" : "3.5vw", // Adjusts according to the viewport width
            fontWeight: 'bold',
            color: "black",
            marginTop: "40px",
            marginBottom: "40px",
            textAlign: "center", // Centers the text
          }}
        >
          How Much Can I Earn With  <b style={{ color: '#FC3837' }}>CodeGrow</b>?
        </VuiTypography>



        {/* revenue */}
        <VuiBox
          style={{
            width: isMobile ? "90vw" : "70vw",
            height: "auto",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            borderRadius: "15px",
            overflow: "hidden",
            backgroundImage: `url(${minicolourbg})`, // Background image for the landing page
            backgroundSize: "cover", // Ensures the image covers the entire area
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
            boxShadow: "0px 4px 16px #2470f8", // Full black shadow
          }}
        >

          {/* Left side */}
          <VuiBox
            style={{
              flex: 1,
              backgroundColor: "transparent", // Light blue with transparency
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <VuiTypography style={{ fontWeight: "bold", fontSize: isMobile ? "5vw" : '1.5vw', color: 'white' }}>
              Revenue Configurator
            </VuiTypography>

            <VuiBox
              style={{
                flex: 1,
                backgroundColor: "transparent", // Light blue with transparency
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                padding: "20px",
              }}
            >
              {/* 1st section */}
              <VuiBox>
                <VuiTypography style={{ fontSize: isMobile ? "4vw" : '1.2vw', color: "white" }}>
                  Apps made per month
                </VuiTypography>
                <RcSlider
                  min={1}
                  max={6}
                  value={appsPerMonth}
                  onChange={value => setAppsPerMonth(value)}
                  trackStyle={[{ backgroundColor: '#1e90ff' }]} // Customize track color
                  handleStyle={[{ borderColor: '#1e90ff', backgroundColor: 'white' }]} // Customize handle color
                  railStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} // Customize rail color
                />
                <VuiTypography style={{ fontSize: isMobile ? "3.7vw" : '1.2vw', color: "white" }}> {appsPerMonth} apps per month</VuiTypography>
              </VuiBox>

              {/* 2nd section */}
              <VuiBox style={{ marginTop: isMobile ? "15px" : "0px" }}>
                <VuiTypography style={{ fontSize: isMobile ? "4vw" : '1.2vw', color: "white" }}>
                  Daily Ad Revenue Per App
                </VuiTypography>
                <RcSlider
                  min={5}
                  max={50}
                  value={dailyRevenue}
                  onChange={setDailyRevenue} // Simplified onChange
                  trackStyle={[{ backgroundColor: '#1e90ff' }]}
                  handleStyle={[{ borderColor: '#1e90ff', backgroundColor: 'white' }]}
                  railStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                />
                <VuiTypography style={{ fontSize: isMobile ? "3.7vw" : '1.2vw', color: "white" }}> ${dailyRevenue} per day</VuiTypography>
              </VuiBox>

              {/* 3rd section */}
              <VuiBox style={{ marginTop: isMobile ? "15px" : "0px" }}>
                <VuiTypography style={{ fontSize: isMobile ? "4vw" : '1.2vw', color: "white" }}>
                  Flippa Sale Price Per App
                </VuiTypography>
                <RcSlider
                  min={1000}
                  max={25000}
                  value={flippaSalePrice}
                  onChange={setFlippaSalePrice} // Simplified onChange
                  trackStyle={[{ backgroundColor: '#1e90ff' }]}
                  handleStyle={[{ borderColor: '#1e90ff', backgroundColor: 'white' }]}
                  railStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                />
                <VuiTypography style={{ fontSize: isMobile ? "3.7vw" : '1.2vw', color: "white" }}>${flippaSalePrice} per app</VuiTypography>
              </VuiBox>
            </VuiBox>

          </VuiBox>


          {/* Right side */}
          <VuiBox
            style={{
              flex: 1,
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "20px",
            }}
          >
            <VuiTypography style={{ fontWeight: "bold", fontSize: isMobile ? "5vw" : '1.6vw', color: '#FC3837' }}>
              Revenue Breakdown
            </VuiTypography>

            <TableContainer component={Paper} style={{ marginTop: '20px', }}>
              <Table style={{ backgroundColor: 'white' }}>

                <TableBody style={{ backgroundColor: 'white' }}>
                  <TableRow style={{ backgroundColor: 'white' }}>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Apps made in a month:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4.5vw" : '1.4vw' }}>{appsPerMonth}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Apps made in a year:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>{appsPerMonth * 12}</TableCell>
                  </TableRow>
                  <hr />
                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Monthly Ad revenue per app:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>${monthlyRevenuePerApp.toLocaleString()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw', }}><strong>Yearly Ad revenue per app:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>${yearlyRevenuePerApp.toLocaleString()}</TableCell>
                  </TableRow>
                  <hr />
                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Total revenue from apps in a year:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>${totalRevenuePerYear.toLocaleString()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Monthly Income Breakdown:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>${totalRevenuePerMonth.toLocaleString()}</TableCell>
                  </TableRow>
                  <hr />

                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Total from selling apps on Flippa:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>${totalFlippaRevenue.toLocaleString()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Total combined revenue in a year:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>${totalCombinedRevenue.toLocaleString()}</TableCell>
                  </TableRow>
                  <hr />

                </TableBody>

              </Table>
            </TableContainer>

            <VuiTypography style={{ fontWeight: "bold", marginTop: "20px", fontSize: isMobile ? "4.8vw" : '1.6vw', color: 'green' }}>
              <strong>Total monthly revenue:</strong> ${totalCombinedMonthly.toLocaleString()}
            </VuiTypography>
          </VuiBox>

        </VuiBox>

        {/* revenue end */}


        <br />


        {/* first white bubble end */}
      </VuiBox>

      {/* fourth section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          backgroundColor: "#F7F8FB",

          minHeight: "100vh",
          marginTop: '0px',

          padding: "0", // Remove padding to avoid affecting width
        }}
      >


        {/* fourth white section*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={isMobile ? "100%" : "100%"}
          // maxWidth="800px"
          flexDirection={isMobile ? "column" : "row"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "transparent", // Fading effect
          }}
          borderRadius="25px"
        >

          {/* Buttons on the Right */}
          <VuiBox
            display="flex"
            justifyContent={isMobile ? "center" : "space-between"}
            flexDirection={"column"}
            alignItems="start"
            height={isMobile ? "100%" : "30vw"}
            // maxWidth="800px"
            width={isMobile ? "100%" : "50%"}
            padding="20px"
            style={{
              background: "transparent", // Fading effect
            }}
            borderRadius={borders.borderRadius.md}
          >


            <div>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "6.5vw" : "3.8vw",
                  fontWeight: "bold",
                  color: "black",
                  marginBottom: "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                Effortlessly Build Your Portfolio with             </VuiTypography>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "6.5vw" : "3.8vw",
                  fontWeight: "bold",
                  color: "#FE3737",
                  marginBottom: isMobile ? "10%" : "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                AI-Generated Apps
              </VuiTypography>

            </div>

            <div>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "5vw" : "1.6vw",
                  fontWeight: "bold",
                  color: "#69696a",
                  marginBottom: isMobile ? "10%" : "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                <bold style={{ color: 'black' }}>                Leverage AI-powered insights to design, build, and launch iOS apps effortlessly.                 </bold> Perfect your projects with smart recommendations and streamlined workflows.
              </VuiTypography>



            </div>

            <div>
              <VuiButton
                aligncontent="start"
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => navigate("/authentication/sign-in")}
                style={{
                  backgroundColor: 'transparent',
                  border: " 2px solid #FE3737",
                  color: " #FE3737",
                  fontSize: isMobile ? "3.5vw" : "1.5vw",
                  padding: "10px 20px",
                  borderRadius: '55px',

                }}
              >
                Get Started
              </VuiButton>
            </div>

          </VuiBox>

          <VuiBox

            width={isMobile ? "100%" : "45%"}
          >
            <img
              src={ai1}
              alt="Centered Image"
              style={{
                borderRadius: "15px",
                width: isMobile ? "100%" : "40vw",
                objectFit: "contain", // Ensures the image scales nicely within the specified width
                margin: "0 auto", // Centers the image horizontally
              }}
            />
          </VuiBox>

        </VuiBox>
        {/* fourth white section end*/}


        {/* fifth white section*/}
        <VuiBox
          display={isMobile ? "flex" : "none"}
          justifyContent="space-between"
          alignItems="center"
          width={isMobile ? "100%" : "100%"}
          // maxWidth="800px"
          flexDirection={isMobile ? "column" : "row"}
          marginTop="0%"
          padding="40px"
          style={{
            background: "transparent", // Fading effect
          }}
          borderRadius="25px"
        >



          {/* Buttons on the Right */}
          <VuiBox
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            alignItems="start"
            height={isMobile ? "100%" : "37vw"}
            // maxWidth="800px"
            width={isMobile ? "100%" : "50%"}
            padding="20px"
            style={{
              background: "transparent", // Fading effect
            }}
            borderRadius={borders.borderRadius.md}
          >


            <div>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "6.5vw" : "3.8vw",
                  fontWeight: "bold",
                  color: "black",
                  marginBottom: "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                Expand Your App’s Features with              </VuiTypography>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "6.5vw" : "3.8vw",
                  fontWeight: "bold",
                  color: "#FE3737",
                  marginBottom: isMobile ? "10%" : "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                AI Code Expander
              </VuiTypography>

            </div>

            <div>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "5vw" : "1.6vw",
                  fontWeight: "bold",
                  color: "#69696a",
                  marginBottom: isMobile ? "10%" : "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                <bold style={{ color: 'black' }}>Add advanced features and refine layouts post-project creation. </bold> Customize your app’s functionality and design to align perfectly with your vision.
              </VuiTypography>



            </div>

            <div>
              <VuiButton
                aligncontent="start"
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => navigate("/authentication/sign-in")}
                style={{
                  backgroundColor: 'transparent',
                  border: " 2px solid #FE3737",
                  color: " #FE3737",
                  fontSize: isMobile ? "3.5vw" : "1.5vw",
                  padding: "10px 20px",
                  borderRadius: '55px',
                  marginBottom: isMobile ? "10%" : "0px"

                }}
              >
                Get Started
              </VuiButton>
            </div>

          </VuiBox>

          <VuiBox

            width={isMobile ? "100%" : "48%"}
          >
            <img
              src={ai2}
              alt="Centered Image"
              style={{
                borderRadius: "15px",
                width: isMobile ? "100%" : "40vw",
                objectFit: "contain", // Ensures the image scales nicely within the specified width
                margin: "0 auto", // Centers the image horizontally
              }}
            />
          </VuiBox>
        </VuiBox>
        {/* fifth white section end*/}

        {/* fifth white section*/}
        <VuiBox
          display={isMobile ? "none" : "flex"}
          justifyContent="space-between"
          alignItems="center"
          width={isMobile ? "100%" : "100%"}
          // maxWidth="800px"
          flexDirection={isMobile ? "column" : "row"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "transparent", // Fading effect
          }}
          borderRadius="25px"
        >

          <VuiBox

            width={isMobile ? "100%" : "48%"}
          >
            <img
              src={ai2}
              alt="Centered Image"
              style={{
                borderRadius: "15px",
                width: isMobile ? "100%" : "40vw",
                objectFit: "contain", // Ensures the image scales nicely within the specified width
                margin: "0 auto", // Centers the image horizontally
              }}
            />
          </VuiBox>

          {/* Buttons on the Right */}
          <VuiBox
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            alignItems="start"
            height={isMobile ? "100%" : "30vw"}
            // maxWidth="800px"
            width="50%"
            padding="20px"
            style={{
              background: "transparent", // Fading effect
            }}
            borderRadius={borders.borderRadius.md}
          >


            <div>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "4.5vw" : "3.8vw",
                  fontWeight: "bold",
                  color: "black",
                  marginBottom: "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                Expand Your App’s Features with              </VuiTypography>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "4.5vw" : "3.8vw",
                  fontWeight: "bold",
                  color: "#FE3737",

                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                AI Code Expander
              </VuiTypography>

            </div>

            <div>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "4.5vw" : "1.6vw",
                  fontWeight: "bold",
                  color: "#69696a",
                  marginBottom: "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                <bold style={{ color: 'black' }}>Add advanced features and refine layouts post-project creation. </bold> Customize your app’s functionality and design to align perfectly with your vision.
              </VuiTypography>



            </div>

            <div>
              <VuiButton
                aligncontent="start"
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => navigate("/authentication/sign-in")}
                style={{
                  backgroundColor: 'transparent',
                  border: " 2px solid #FE3737",
                  color: " #FE3737",
                  fontSize: isMobile ? "3.5vw" : "1.5vw",
                  padding: "10px 20px",
                  borderRadius: '55px',

                }}
              >
                Get Started
              </VuiButton>
            </div>

          </VuiBox>
        </VuiBox>
        {/* fifth white section end*/}


        {/* sixth white bubble*/}
        <VuiBox
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
          width={isMobile ? "90%" : "90%"}
          // maxWidth="800px"
          marginTop="5%"
          padding="0px"

          style={{
            backgroundImage: `url(${minicolourbg})`, // Background image for the landing page

            backgroundSize: "cover", // Ensures the image covers the entire area
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
          }}

          borderRadius="25px"
        >
          <VuiBox
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            alignItems="start"
            height={isMobile ? "100%" : "32vw"}
            // maxWidth="800px"
            width={isMobile ? "100%" : "60%"}
            padding="40px"
            style={{
              // background: "linear-gradient(to top, white 60%, transparent 100%)", // Fading effect
            }}
            borderRadius={borders.borderRadius.md}
          >


            <div>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "6.5vw" : "3.8vw",
                  fontWeight: "bold",
                  color: "white",
                  marginBottom: "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                Learn From The Best in
              </VuiTypography>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "6vw" : "3.4vw",
                  fontWeight: "bold",
                  color: "#FE3737",
                  marginBottom: isMobile ? "10%" : "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >
                Our Private Community
              </VuiTypography>

            </div>

            <div>

              <VuiTypography
                style={{
                  textAlign: "start",
                  fontSize: isMobile ? "5vw" : "1.6vw",
                  fontWeight: "bold",
                  color: "white",
                  marginBottom: isMobile ? "10%" : "0px",
                  lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                }}
              >

                Gain access to our exclusive CodeGrow course, guiding you step-by-step on using the software to create, launch, and market apps effectively. Learn to maximize ad revenue and succeed in the iOS app market.              </VuiTypography>



            </div>

            <div>
              <VuiButton
                aligncontent="start"
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => navigate("/authentication/sign-in")}
                style={{
                  backgroundColor: '#FE3737',

                  color: " white",
                  fontSize: isMobile ? "3.5vw" : "1.5vw",
                  padding: "10px 20px",
                  borderRadius: '55px',

                }}
              >
                Get Started
              </VuiButton>
            </div>

          </VuiBox>



          <VuiBox
            display="flex"
            justifyContent="end"
            alignItems="end"
            width={isMobile ? "100%" : "48%"}
            // maxWidth="800px"
            marginTop="0%"
            padding="0px"


            borderRadius="25px"
          >
            <img
              src={ai3}
              alt="Centered Image"
              style={{
                borderRadius: "15px",
                width: isMobile ? "100%" : "43vw",
                objectFit: "contain", // Ensures the image scales nicely within the specified width
                margin: "0 auto", // Centers the image horizontally
              }}
            />
          </VuiBox>

          {/* Buttons on the Right */}

        </VuiBox>
        {/* sixth white bubble end */}

        <VuiBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          marginTop="8%"
          style={{ textAlign: "center" }}
          width="80vw"
        >
          <div>

            <VuiTypography
              style={{
                textAlign: "center",
                fontSize: isMobile ? "4.5vw" : "3.8vw",
                fontWeight: "bold",
                color: "black",
                marginBottom: "0px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
            >
              Transparent Pricing for Every Developer
            </VuiTypography>



          </div>
          <br />

          <div style={{ width: "70%" }}>

            <VuiTypography
              style={{
                textAlign: "center",
                fontSize: isMobile ? "4.5vw" : "1.6vw",
                fontWeight: "bold",
                color: "#69696a",
                marginBottom: "0px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
            >
              Affordable plans designed for app creators of all levels. Know exactly what you're paying—no surprises
            </VuiTypography>



          </div>
        </VuiBox>


        {/* pricing  bubble*/}
        {/* Pricing Section */}
        <VuiBox id="pricing-section" style={{ marginTop: "5%" }}>
          {/* Slider Toggle */}
          <VuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom="20px"
            style={{ textAlign: "center" }}
          >
            <VuiButton


              onClick={() => setIsYearly(true)}
              style={{
                width: "180px",
                borderRadius: "30px",
                margin: "0 10px",
                backgroundColor: isYearly ? "#fe3737" : "transparent",
                color: isYearly ? "white" : "#fe3737",
                border: ' 2px solid #fe3737'
              }}
            >
              Yearly (Save 16%)
            </VuiButton>
            <VuiButton


              onClick={() => setIsYearly(false)}
              style={{
                width: "180px",
                borderRadius: "30px",
                margin: "0 10px",
                backgroundColor: !isYearly ? "#fe3737" : "transparent",
                color: !isYearly ? "white" : "#fe3737",
                border: ' 2px solid #fe3737'

              }}
            >
              Monthly
            </VuiButton>
          </VuiBox>

          {/* Pricing Bubble */}
          <VuiBox
            display="flex"
            justifyContent="space-evenly"
            flexDirection="row"
            alignItems="center"
            width={isMobile ? "100%" : "100%"}
            margin="auto"
            padding="40px"
            style={{
              background: "transparent",
            }}
            borderRadius="25px"
          >
            <VuiBox
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              width={isMobile ? "100%" : "100%"}
              padding="40px"
              style={{
                background: "white",
              }}
              borderRadius="25px"
            >
              <VuiBox
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  width: "100%",
                  padding: isMobile ? "0px" : "0 0px",
                }}
              >
                <img
                  src={isYearly ? yearly : monthly}
                  alt={isYearly ? "Yearly Package" : "Monthly Package"}
                  style={{ width: isMobile ? "20vw" : "5vw", height: "auto" }}
                />
                <br />
                <VuiTypography style={{ fontSize: isMobile ? "5.8vw" : "2.3vw", fontWeight: "bold", color: "black" }}>
                  {isYearly ? "Yearly Package" : "Monthly Package"}
                </VuiTypography>
                <br />
                <VuiTypography style={{ fontSize: isMobile ? "5.8vw" : "2.6vw", fontWeight: "bold", color: "black" }}>
                  {isYearly ? "$20/pm" : "$25/pm"}
                </VuiTypography>
              </VuiBox>
              <br />
              <center>
                <VuiButton
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/authentication/sign-in")}
                  style={{
                    backgroundColor: "transparent",
                    border: "2px solid #fe3737",
                    color: "#fe3737",
                    fontSize: isMobile ? "3.5vw" : "1.5vw",
                    padding: "10px 20px",
                    width: isMobile ? "100%" : "20vw",
                    borderRadius: "55px",
                  }}
                >
                  Get Started
                  <FaArrowRightLong style={{ marginLeft: "8px" }} />
                </VuiButton>
              </center>
              <br />
              <VuiTypography style={{ fontSize: isMobile ? "4vw" : "1.2vw", color: "black", marginTop: "10px" }}>
                {[
                  ...(isYearly ? ["Full Market Ready IOS Apps"] : []), // Only include this if isYearly is true

                  "AI Idea Finder",
                  "AI App Designer",
                  "AI Coder",
                  "AI Code Expander",
                  "Canva Design Templates",
                  "Flippa Sale Templates",
                  "AI Chrome Extensions",
                  "App Store Icon Size Generator",
                ].map((feature, index) =>
                  feature ? (
                    <li key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px", padding: "0px" }}>
                      <span style={{ color: "#fe3737", marginRight: "8px" }}>
                        <IoMdCheckmarkCircle style={{ marginRight: "10px", fontSize: isMobile ? "4.5vw" : "2vw" }} />
                      </span>
                      {feature}
                    </li>
                  ) : null
                )}

              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </VuiBox>

        {/* pricing bubble end */}


        {/* third white bubble*/}
        <VuiBox

          width={isMobile ? "100%" : "90%"}
          // maxWidth="800px"
          marginTop="5%"
          height={isMobile ? "150vw" : " 75vw"}
          padding="40px"
          style={{
            backgroundImage: `url(${minicolourbg})`, // Background image for the landing page

            backgroundSize: "cover", // Ensures the image covers the entire area
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
          }}
          borderRadius="25px"
        >
          <VuiBox
            display="flex"
            flexDirection="column"

            justifyContent={"space-between"}
            alignItems="center"
            marginLeft={isMobile ? "0px" : '15%'}
            width={isMobile ? "100%" : "70%"}
            // maxWidth="800px"

            height={" 100%"}
            padding="0px"

            borderRadius="25px"
          >


            <VuiTypography
              style={{
                textAlign: "center",
                fontSize: isMobile ? "6.5vw" : "5.8vw",
                fontWeight: "bold",
                color: "white",
                marginTop: "15px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
            >
              <bold style={{
                color: "#FE3737",
              }}> Get Started Today</bold>
            </VuiTypography>


            <VuiTypography
              style={{
                marginTop: isMobile ? "10%" : "15px",

                textAlign: "center",
                fontSize: isMobile ? "5.5vw" : "1.6vw",
                fontWeight: "bold",
                color: "white",
                marginBottom: isMobile ? "6%" : "0px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
            >

              Launch your first app project with expert guidance and AI tools.            </VuiTypography>
            <br />

            <VuiButton
              aligncontent="center"
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigate("/authentication/sign-in")}
              style={{
                backgroundColor: '#1079FB',

                color: " white",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px',
                marginBottom: isMobile ? "10%" : "0px"

              }}
            >
              Get Started Today
            </VuiButton>
            <br />
            <img
              src={macbook} // Replace with your GIF URL
              alt="Centered Image"
              style={{
                borderRadius: "15px",
                width: isMobile ? "90vw" : "70vw",
                objectFit: "contain", // Ensures the image scales nicely within the specified width
                marginBottom: "-0%", // Centers the image horizontally
                boxShadow: "0 0 20px 5px rgba(255, 255, 255, 0.7)", // Adds a white glow effect
              }}
            />


          </VuiBox>


        </VuiBox>
        {/* third white bubble end */}


        {/* FAQ */}
        <VuiBox
          style={{
            marginTop: "100px",
            textAlign: "center",
            padding: "40px 20px",


            background: "linear-gradient(to top, #030515 0%, #7f8c8d 70%, #f7f8fb 100%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
          }}
        >
          <VuiTypography
            style={{
              fontSize: "48px",
              color: "#FC3837",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <b style={{ fontSize: isMobile ? "5.8vw" : "4.2vw", color: "black" }}>
              Frequently Asked Questions
            </b>
          </VuiTypography>

          {/* FAQ list */}
          <VuiBox
            style={{
              width: isMobile ? "90%" : "80%",
              maxWidth: "1200px",
              marginTop: "30px",
            }}
          >
            {faqData.map((faq, index) => (
              <VuiBox
                key={index}
                style={{
                  marginBottom: "20px",
                  padding: "20px",
                  background: "#1a1a2e",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => toggleQuestion(index)}
              >
                {/* Question */}
                <VuiTypography
                  style={{
                    color: "#FC3837",
                    fontSize: isMobile ? "4vw" : "1.5vw",
                  }}
                >
                  {faq.question}
                </VuiTypography>

                {/* Answer */}
                {openQuestion === index && (
                  <VuiTypography
                    style={{
                      marginTop: "10px",
                      fontSize: isMobile ? "4vw" : "1.2vw",
                      color: "white",
                      transition: "max-height 0.3s ease-in-out",
                      overflow: "hidden",
                    }}
                  >
                    {faq.answer}
                  </VuiTypography>
                )}
              </VuiBox>
            ))}
          </VuiBox>
        </VuiBox>
        {/* triple section end */}


      </VuiBox>
      {/* fourth section end */}



      <br />



    </CoverLayout>
    </>
  );
}

export default SignIn;
