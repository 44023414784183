import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, MenuItem, FormControl, Select } from "@mui/material";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import DashboardLayout from "examples/LayoutContainers/TableDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { Tooltip } from "@mui/material";
import { IoCopy } from "react-icons/io5";
import VuiInput from "components/VuiInput";
import { Description } from "@mui/icons-material";
import { handleAdTypeSelectFunction } from './handleAdTypeSelectFunction';  // For handling ad type selection
import { buildAiProject } from './aiProjectBuilder';  // For handling ad type selection
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery

function AiCode() {
  const [openModal, setOpenModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [colorPalette, setColorPalette] = useState([]);
  const [appstoreName, setAppstoreName] = useState('');
  const [selectedPage, setSelectedPage] = useState(0); // Default value is 0 (no pages selected)
  const [openWarningModal, setOpenWarningModal] = useState(false); // For warning modal
  const [nextPage, setNextPage] = useState(null); // Store the page number the user is trying to switch to
  const [pageDescription, setPageDescription] = useState('');
  const [loading2, setLoading2] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed

  const [pageConfigs, setPageConfigs] = useState({
    1: { pageName: '', pageDescription: '', selectedColors: [], adsEnabled: false, adType: '', isHomePage: true, parentPage: null },
    2: { pageName: '', pageDescription: '', selectedColors: [], adsEnabled: false, adType: '', isHomePage: false, parentPage: null },
    3: { pageName: '', pageDescription: '', selectedColors: [], adsEnabled: false, adType: '', isHomePage: false, parentPage: null },
    4: { pageName: '', pageDescription: '', selectedColors: [], adsEnabled: false, adType: '', isHomePage: false, parentPage: null },
  });

  const handleAdTypeSelect = handleAdTypeSelectFunction(setPageConfigs);


  const handlePageNameChange = (pageNumber, name) => {
    setPageConfigs(prev => ({
      ...prev,
      [pageNumber]: {
        ...prev[pageNumber],
        pageName: name,
      },
    }));
  };
  const handlePageDescriptionChange = (pageNumber, description) => {
    setPageConfigs(prev => ({
      ...prev,
      [pageNumber]: {
        ...prev[pageNumber],
        pageDescription: description,
      },
    }));
  };
  const handleColorSelect = (pageNumber, color) => {
    setPageConfigs(prev => {
      const selectedColors = prev[pageNumber]?.selectedColors || [];
      const updatedColors = selectedColors.includes(color)
        ? selectedColors.filter(c => c !== color)
        : [...selectedColors, color];

      return {
        ...prev,
        [pageNumber]: {
          ...prev[pageNumber],
          selectedColors: updatedColors,
        },
      };
    });
  };

  const [pages, setPages] = React.useState([
    { name: '', isHomePage: false, parentPage: '' }, // Default structure for one page
  ]);




  useEffect(() => {
    const fetchProjects = async () => {
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          setProjects(userDocSnapshot.data().projects || []);
        }
      }
    };

    if (openModal) {
      fetchProjects();
    }
  }, [openModal]);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [hasSelectedPageBefore, setHasSelectedPageBefore] = useState(false);

  const handlePageChangeWithConfirmation = (pageNumber) => {
    if (hasSelectedPageBefore && selectedPage !== pageNumber) {
      setNextPage(pageNumber);  // Store the page number the user wants to switch to
      setOpenWarningModal(true); // Show the warning modal
    } else {
      setSelectedPage(pageNumber);  // Change page immediately if no confirmation needed
      setHasSelectedPageBefore(true); // Mark that the user has made a selection
    }
  };

  const handleConfirmPageChange = () => {
    setSelectedPage(nextPage);  // Confirm the page change
    setOpenWarningModal(false); // Close the warning modal
  };

  const handleCancelPageChange = () => {
    setOpenWarningModal(false); // Cancel the page change and close the modal
  };

  const parseProjectDetails = (details) => {
    const sectionHeaders = [
      "Project Overview",
      "Pages",
      "Functionality",
      "Ads Placement",
      "Recommended Ad Frequency",
      "Color Palette",
      "App Store Name Idea",
      "App Store Title",
      "Promotional Text",
      "Long Detailed Description",
      "Keywords",
      "Logo Idea",
    ];

    const parsedDetails = {};
    let currentSection = null;
    const lines = details.split("\n");

    let tempColorPalette = [];
    let appStoreName = ""; // Variable to store the app store name
    let lookingForAppStoreName = false; // Flag to handle multiline app name cases

    lines.forEach((line) => {
      const section = sectionHeaders.find((header) => line.startsWith(header));

      if (section) {
        currentSection = section;
        parsedDetails[currentSection] = "";

        // Check if this is the start of the "App Store Name Idea" section
        if (currentSection === "App Store Name Idea") {
          lookingForAppStoreName = true;
        } else {
          lookingForAppStoreName = false; // Reset the flag when moving to a new section
        }
      } else if (currentSection) {
        parsedDetails[currentSection] += line + "\n";

        if (currentSection === "Color Palette") {
          const colorMatch = line.match(/#[0-9A-Fa-f]{6}/g);
          if (colorMatch) {
            tempColorPalette = [...tempColorPalette, ...colorMatch];
          }
        }
      }

      // Handle "App Store Name Idea" (both inline and multiline formats)
      if (lookingForAppStoreName) {
        if (line.includes("App Store Name Idea:")) {
          // Inline format: Extract name after the colon
          const extractedName = line.split(":").slice(1).join(":").trim();
          if (extractedName) {
            appStoreName = extractedName;
            lookingForAppStoreName = false; // Name found, stop looking further
          }
        } else if (!line.startsWith("App Store Name Idea") && line.trim() !== "") {
          // Multiline format: Use the next line as the name
          appStoreName = line.trim();
          lookingForAppStoreName = false; // Name found, stop looking further
        }
      }
    });

    // Check if no name was found and set default value
    if (!appStoreName) {
      appStoreName = "No name found";
    }

    // Update the app store name state
    setAppstoreName(appStoreName);

    // Set the color palette state
    setColorPalette(tempColorPalette);

    const formattedDetails = Object.entries(parsedDetails).map(([section, content], index) => {
      return (
        <VuiTypography
          key={index}
          variant="body1"
          color="white"
          style={{ marginBottom: "16px", fontWeight: section.includes("Overview") ? "bold" : "normal" }}
        >
          <strong>{section}:</strong> {content.trim()}
        </VuiTypography>
      );
    });

    const formattedDetailsLong = lines.map((line, index) => {
      const isHeader = line.startsWith("# ");
      return (
        <VuiTypography
          key={index}
          variant="body1"
          color="white"
          style={{ marginBottom: "16px", fontWeight: isHeader ? "bold" : "normal" }}
        >
          {isHeader ? line.substring(2) : line}
        </VuiTypography>
      );
    });

    return { formattedDetails, formattedDetailsLong };
  };

  const handleSelectProject = (project) => {
    const { formattedDetails, formattedDetailsLong } = parseProjectDetails(project.details);
    setSelectedProject({
      ...project,
      formattedDetails,
      formattedDetailsLong,
    });
    setOpenModal(false);
  };

  const openDetailsModalHandler = () => setOpenDetailsModal(true);
  const closeDetailsModalHandler = () => setOpenDetailsModal(false);



  async function logNonEmptyPages(pageConfigs) {
    const filteredPageConfigs = Object.entries(pageConfigs)
      .filter(([key, config]) => config.pageName !== '') // Ensure 'pageName' is not empty
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
  
    try {
      // Await the AI project builder process
      const aiResult = await buildAiProject(appstoreName, filteredPageConfigs);
      console.log(aiResult);
    } catch (error) {
      console.error("Error during AI project building:", error);
    }
  }
  

  const handleClick = async () => {
    setLoading2(true); // Start loading
    try {
      await logNonEmptyPages(pageConfigs); // Call the function
    } catch (error) {
      console.error("Error in logNonEmptyPages:", error);
    } finally {
      setLoading2(false); // Stop loading
      setShowPopup(true); // Show the popup message

    }
  };

  const closePopup = () => {
    setShowPopup(false); // Close the popup
  };


  const navigateAiCodeExpander = () => {
    navigate("/aicodeexpander");
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}> 
          <Card style={{ padding: "16px", marginBottom: "16px" }}>
            <VuiBox display="flex" flexDirection={isMobile? "column" : "row"} justifyContent="space-between" alignItems="center">
              <VuiTypography variant="lg" color="white" style={{marginBottom:isMobile? "25px":"0px"}}>AI Code</VuiTypography>
             <div>
             <Button variant="contained" style={{ color: 'white', marginRight:'10px' }} color="primary" onClick={handleOpen}>
                Select Project
              </Button>
              <Button variant="contained" style={{ color: 'white' }} color="primary" onClick={navigateAiCodeExpander}>
                AI Code Expander
              </Button>
             </div>
            </VuiBox>
          </Card>

          {loading && (
            <VuiBox display="flex" justifyContent="center" my="16px">
              <CircularProgress color="inherit" />
            </VuiBox>
          )}

          {selectedProject && (
            <Card style={{ padding: "16px", marginTop: "16px" }}>
              <VuiBox display="flex" alignItems="center" justifyContent="space-between">
                <VuiTypography variant="h6" color="white">{appstoreName}</VuiTypography>
                <VuiBox display="flex" alignItems="center" gap={1}>
                  {colorPalette.length > 0 ? (
                    <VuiBox display="flex" flexDirection="row" alignItems="center">
                      {colorPalette.map((colorValue, index) => (
                        <Tooltip key={index} title={<VuiBox><VuiTypography variant="body2" color="white">{colorValue}</VuiTypography></VuiBox>} placement="top">
                          <div style={{ marginRight: '8px', position: 'relative' }}>
                            <div
                              style={{
                                backgroundColor: colorValue,
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                border: '1px solid white',
                              }}
                            ></div>
                          </div>
                        </Tooltip>
                      ))}
                    </VuiBox>
                  ) : (
                    <VuiTypography variant="body2" color="white">No color palette available.</VuiTypography>
                  )}
                </VuiBox>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={openDetailsModalHandler}
                  style={{ marginLeft: "16px" }}
                >
                  <IoCopy />
                </Button>
              </VuiBox>


              {/* New section for page selection */}
              <VuiBox my={4}>
                <VuiTypography variant="h6" color="white" style={{ marginBottom: '10px' }} align="center">
                  Select Number Of Pages
                </VuiTypography>
                <VuiBox display="flex" justifyContent="center" gap={2}>
                  {[1, 2, 3, 4].map(page => (
                    <Button
                      key={page}
                      variant="outlined"
                      color="primary"
                      onClick={() => handlePageChangeWithConfirmation(page)}
                      style={{ margin: "10px" }}
                    >
                      {page}
                    </Button>

                  ))}
                </VuiBox>



              </VuiBox>

            </Card>

          )}
          <VuiBox >

            {selectedPage > 0 && (
              <VuiBox mt={4}>
                {[...Array(selectedPage)].map((_, index) => {
                  const pageNumber = index + 1;
                  const pageConfig = pageConfigs[pageNumber];

                  return (
                    <Card style={{ padding: "16px", paddingTop: "0px", marginTop: "16px" }} key={pageNumber}>
                      <VuiBox mt={2} p={2} border="0px solid white" borderRadius="8px">
                        <VuiTypography variant="h6" color="white">
                          Project Configuration for Page {pageNumber}
                        </VuiTypography>
                      </VuiBox>
                      <VuiBox display="flex" alignItems="center" mb="8px">


                      </VuiBox>




                      <VuiBox mt={4}>
                        <VuiTypography variant="h6" style={{ marginLeft: '5px', marginBottom: '10px' }} color="white">Name of Page</VuiTypography>

                        <VuiInput
                          value={pageConfig.pageName}
                          onChange={(e) => handlePageNameChange(pageNumber, e.target.value)}
                          placeholder="Enter page name"
                          fullWidth
                        />

                        <VuiTypography variant="h6" color="white" mt={4}>Select Colors for Page</VuiTypography>
                        <VuiBox display="flex" alignItems="center" gap={1} mt={2}>
                          {colorPalette.length > 0 ? (
                            <VuiBox display="flex" flexDirection="row" alignItems="center">
                              {colorPalette.map((colorValue, index) => (
                                <Tooltip key={index} title={<VuiBox><VuiTypography variant="body2" color="white">{colorValue}</VuiTypography></VuiBox>} placement="top">
                                  <div
                                    onClick={() => handleColorSelect(pageNumber, colorValue)}
                                    style={{
                                      marginRight: '10px',
                                      position: 'relative',
                                      cursor: 'pointer',
                                      borderRadius: '50%',
                                      border: '1px solid white',
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: colorValue,
                                      outline: pageConfigs[pageNumber].selectedColors.includes(colorValue) ? '2.5px solid #fff' : 'none', // Add an outline if selected
                                    }}
                                  ></div>

                                </Tooltip>
                              ))}
                            </VuiBox>
                          ) : (
                            <VuiTypography variant="body2" color="white">No color palette available.</VuiTypography>
                          )}

                        </VuiBox>

                        <VuiBox mt={2}>
                          <VuiTypography variant="h6" color="white" mt={4}>Select Ad Type</VuiTypography>

                          <VuiBox display="flex" gap={2} mt={2}>
                            {["Banner", "Interstitial", "None"].map(adType => (
                              <Button
                                key={adType}
                                variant={pageConfig.adType === adType ? "contained" : "outlined"}
                                color="primary"
                                onClick={() => handleAdTypeSelect(pageNumber, adType)}
                                style={{ margin: "10px", color: 'white' }}
                              >
                                {adType}
                              </Button>
                            ))}
                          </VuiBox>
                        </VuiBox>
                      </VuiBox>

                      <VuiTypography variant="h6" style={{ marginLeft: '5px', marginBottom: '10px' }} color="white" mt={4}>Page Description</VuiTypography>
                      <VuiInput
                        value={pageConfig.pageDescription}
                        onChange={(e) => handlePageDescriptionChange(pageNumber, e.target.value)}
                        placeholder="Enter page description"
                        fullWidth
                        multiline
                        rows={4}
                      />
                    </Card>
                  );
                })}
                <Card style={{ padding: "16px", paddingTop: "0px", marginTop: "16px" }} >

                  <VuiBox mt="20px" p="16px" backgroundColor="#1B1D34" borderRadius="8px">
                    <button

                      style={{
                        width: '100%',
                        padding: '12px',
                        backgroundColor: '#0175ff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontWeight: 'bold'
                      }}
                      // onClick={() => logNonEmptyPages(pageConfigs)} // Log the `pageConfigs` object
                      onClick={handleClick}
                      disabled={loading2}
                    >
                      {loading2 ? "Coding your project, please wait..." : "Start AI Coding"}
                    </button>
                  </VuiBox>

                </Card>

              </VuiBox>


            )}



          </VuiBox>


          {showPopup && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#282c34',
          color: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
          textAlign: 'center',
        }}>
          <p>Your project is being coded by CodeGrow AI now.</p>
          <p>
            Once done, the download will begin. Please allow up to 5 minutes for
            this process to complete.
          </p>
          <button
            onClick={closePopup}
            style={{
              marginTop: '16px',
              padding: '10px 20px',
              backgroundColor: '#0175ff',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Close
          </button>
        </div>
      )}

      {showPopup && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
          onClick={closePopup} // Close popup when clicking outside
        ></div>
      )}



          <Dialog open={openModal} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogContent style={{ color: "white", backgroundColor: "black" }}>
              {projects.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                  }}
                >
                  {projects.map((project, index) => (
                    <Button
                      key={index}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleSelectProject(project)}
                      style={{
                        margin: "8px",
                        width: isMobile? "100%":"150px",
                        textAlign: "center",
                        height: "50px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {project.name}
                    </Button>
                  ))}
                </div>
              ) : (
                <CircularProgress color="inherit" />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={openWarningModal} onClose={handleCancelPageChange}>
            <DialogTitle style={{ color: "white", backgroundColor: "red", marginBottom: '20px' }} >Warning</DialogTitle>
            <DialogContent style={{ color: "black", backgroundColor: "white" }}>
              <VuiTypography variant="body1" color="black">
                Changing the page count might delete any unsaved work. <br /> Do you want to continue?
              </VuiTypography>
            </DialogContent>
            <DialogActions>

              <Button onClick={handleConfirmPageChange} color="primary">
                Continue
              </Button>
              <Button onClick={handleCancelPageChange} style={{ color: 'red' }} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog open={openDetailsModal} onClose={closeDetailsModalHandler} maxWidth="lg" fullWidth>

            <DialogContent style={{ color: "white", backgroundColor: "black" }}>
              <VuiBox>{selectedProject?.formattedDetailsLong}</VuiBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDetailsModalHandler} style={{ backgroundColor: '#0175ff', color: 'white', padding: '10px' }}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AiCode;
