// actions.js

export const handleAdTypeSelectFunction = (setPageConfigs) => (pageNumber, adType) => {
    setPageConfigs(prev => ({
      ...prev,
      [pageNumber]: {
        ...prev[pageNumber],
        adType: adType,  // Store adType for the selected page
      },
    }));
  };
  