import { generateCode } from './aicoder.js'; // Import the function from aicoder.js

export async function buildAiProject(appstoreName, filteredPageConfigs) {
  // Initialize arrays to hold page details and Dart filenames
  let pages = [];
  let dartFileNames = ['main.dart']; // Ensure main.dart is always the first file

  // Loop through filteredPageConfigs to prepare page data
  for (let i = 1; i <= 4; i++) {
    const pageData = filteredPageConfigs[i];

    if (
      pageData &&
      pageData.pageName &&
      pageData.pageDescription &&
      pageData.selectedColors.length > 0 &&
      pageData.adType
    ) {
      // Generate the Dart filename
      const dartFileName = pageData.pageName
        .toLowerCase()
        .replace(/\s+/g, '_')
        .replace(/[^\w_]/g, '') + '.dart';

      // Add page details
      pages.push({
        name: pageData.pageName,
        dartFileName,
        description: pageData.pageDescription,
        palette: pageData.selectedColors.join(', '),
        adType: pageData.adType,
        isHomePage: pageData.isHomePage,
      });

      dartFileNames.push(dartFileName);
    }
  }

  // Construct the prompt for AI generation
  let prompt = `
    You are a highly advanced AI responsible for generating **fully functional, production-ready Flutter code** for an app named "${appstoreName}."

### App Overview:
This app has the following pages, each requiring full implementation:
`;

  // Append page details to the prompt
  pages.forEach((page, index) => {
    prompt += `
    ${index + 1}. **Page: ${page.name}**
       - **Dart File Name**: ${page.dartFileName}
       - **Description**: ${page.description}
       - **Palette**: ${page.palette}
       - **Ad Type**: ${page.adType}
       - **Is Home Page**: ${page.isHomePage}
    `;
  });

  prompt += `

### Key Requirements:
 **Main File**:
   - The first file created must always be **main.dart**, which serves as the entry point for the app.
   - Ensure it properly routes to the home page and initializes all required dependencies.

 **Full Functionality Guarantee**:
   - For every page, all features described in the instructions must be **fully implemented**:
     - Any described functionality (e.g., button actions, data processing, navigation) must work end-to-end.
     - Ensure no missing logic—every piece of functionality must be functional, including:
       - User input handling
       - Data validation
       - API calls (if required)
       - State management and UI updates
     - Provide **any additional helper functions** needed to ensure the app works perfectly.

 **Ad Integration**:
   - Integrate ads within the specified functionality of each page:
     - **Banner Ads**: Embed within static elements like headers or footers.
     - **Interstitial Ads**: Trigger on specific user actions, such as navigation.

     If adtype is **banner ad** for any of the pages, then integrate the code below onto that specific page's code:
     

     import 'package:google_mobile_ads/google_mobile_ads.dart';

     BannerAd? _bannerAd;
     bool _isBannerAdLoaded = false;
     @override
     void initState() {
       super.initState();
       _loadBannerAd();
     }
     void _loadBannerAd() {
       _bannerAd = BannerAd(
         adUnitId: 'your-banner-ad-unit-id',
         size: AdSize.banner,
         listener: BannerAdListener(
           onAdLoaded: (Ad ad) {
             setState(() {
               _isBannerAdLoaded = true;
             });
           },
           onAdFailedToLoad: (Ad ad, LoadAdError error) {
             ad.dispose();
           },
         ),
         request: AdRequest(),
       )..load();
     }

     if (_isBannerAdLoaded)
       SizedBox(
         height: _bannerAd!.size.height.toDouble(),
         width: _bannerAd!.size.width.toDouble(),
         child: AdWidget(ad: _bannerAd!),
       );


     If adtype is **interstitial ad** for any of the pages, then integrate the code below onto that specific page's code:


     import 'package:google_mobile_ads/google_mobile_ads.dart';

     InterstitialAd? _interstitialAd;
     bool _isInterstitialAdLoaded = false;
     @override
     void initState() {
       super.initState();
       _loadInterstitialAd();
     }
     // Load Interstitial Ad
     void _loadInterstitialAd() {
       InterstitialAd.load(
         adUnitId: 'your-interstitial-ad-unit-id',
         request: AdRequest(),
         adLoadCallback: InterstitialAdLoadCallback(
           onAdLoaded: (InterstitialAd ad) {
             _interstitialAd = ad;
             _isInterstitialAdLoaded = true;
           },
           onAdFailedToLoad: (LoadAdError error) {
             _isInterstitialAdLoaded = false;
           },
         ),
       );
     }


 **Beginner-Friendly Design**:
   - Write clear, well-documented, and beginner-friendly code.
   - Avoid abstract constructs or overly complex patterns.
   - Include comments explaining each section and function.

 **File Structure**:
   - Each page must be implemented in a separate Dart file.
   - Ensure the filenames match exactly (e.g., *${dartFileNames.join(", ")}*).

 **UI Layout**:
   - Build all UI elements (buttons, inputs, containers, etc.) as described in the instructions.
   - Follow the specified **palette (${pages.map(page => page.palette).join(", ")})** for consistent design.

 **Error-Free Code**:
   - Validate that all generated code works without runtime errors or missing functionality.
   - Placeholder comments like "// TODO" or "Add functionality here" must not exist.

 **Fully Tested**:
   - Simulate user interactions to verify every feature works correctly.
   - All button actions, inputs, and ads should function exactly as specified.
`;

  console.log("Generated Prompt:", prompt); // Debugging output

  try {
    // Send the prompt and other details to aicoder.js
    console.log("Dart Filenames:", dartFileNames); // Debugging output
    const response = await generateCode(appstoreName, prompt, pages.length, dartFileNames);

    // Validate the generated response
    if (response.includes("TODO") || response.includes("Add functionality here")) {
      throw new Error(
        "Generated code contains incomplete elements. Ensure all features are implemented."
      );
    }

    // Return the fully generated code
    return response;
  } catch (error) {
    console.error("Error generating code:", error);
    throw error; // Pass the error up for handling
  }
}
