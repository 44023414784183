import axios from 'axios';

const apiKey = process.env.REACT_APP_API_KEY;

const logError = (error) => {
    if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
    } else if (error.request) {
        console.error("No response received:", error.request);
    } else {
        console.error("Error setting up request:", error.message);
    }
};

export async function logPageExpansion({ pageName, adType, pageDescription, fileCode }) {
    let prompt = `
You are a highly advanced AI responsible for generating **fully functional, production-ready Flutter code** for an app named "${pageName}".

### App Overview:
Your job is to take the existing code and modify it with the changes in the description given by the user
only expand the file you are given (assume user already has other files referenced )
This app requires full implementation for the page described below:

- **Page Name**: ${pageName}
- **Page Description**: ${pageDescription}
- **Ad type**: ${adType}
- **File Code**: ${fileCode}

`;

prompt += `



 **Full Functionality Guarantee**:
   - For every page, all features described in the instructions must be **fully implemented**:
     - Any described functionality (e.g., button actions, data processing, navigation) must work end-to-end.
     - Ensure no missing logic—every piece of functionality must be functional, including:
       - User input handling
       - Data validation
       - API calls (if required)
       - State management and UI updates
     - Provide **any additional helper functions** needed to ensure the app works perfectly.

 **Ad Integration**:
   - Integrate ads within the specified functionality of each page:
     - **Banner Ads**: Embed within static elements like headers or footers.
     - **Interstitial Ads**: Trigger on specific user actions, such as navigation.

     If adtype is banner , then integrate the code below onto that specific page's code:
     

     import 'package:google_mobile_ads/google_mobile_ads.dart';

     BannerAd? _bannerAd;
     bool _isBannerAdLoaded = false;
     @override
     void initState() {
       super.initState();
       _loadBannerAd();
     }
     void _loadBannerAd() {
       _bannerAd = BannerAd(
         adUnitId: 'your-banner-ad-unit-id',
         size: AdSize.banner,
         listener: BannerAdListener(
           onAdLoaded: (Ad ad) {
             setState(() {
               _isBannerAdLoaded = true;
             });
           },
           onAdFailedToLoad: (Ad ad, LoadAdError error) {
             ad.dispose();
           },
         ),
         request: AdRequest(),
       )..load();
     }

     if (_isBannerAdLoaded)
       SizedBox(
         height: _bannerAd!.size.height.toDouble(),
         width: _bannerAd!.size.width.toDouble(),
         child: AdWidget(ad: _bannerAd!),
       );


     If adtype is interstitial  , then integrate the code below onto that specific page's code:


     import 'package:google_mobile_ads/google_mobile_ads.dart';

     InterstitialAd? _interstitialAd;
     bool _isInterstitialAdLoaded = false;
     @override
     void initState() {
       super.initState();
       _loadInterstitialAd();
     }
     // Load Interstitial Ad
     void _loadInterstitialAd() {
       InterstitialAd.load(
         adUnitId: 'your-interstitial-ad-unit-id',
         request: AdRequest(),
         adLoadCallback: InterstitialAdLoadCallback(
           onAdLoaded: (InterstitialAd ad) {
             _interstitialAd = ad;
             _isInterstitialAdLoaded = true;
           },
           onAdFailedToLoad: (LoadAdError error) {
             _isInterstitialAdLoaded = false;
           },
         ),
       );
     }


 **Beginner-Friendly Design**:
   - Write clear, well-documented, and beginner-friendly code.
   - Avoid abstract constructs or overly complex patterns.
   - Include comments explaining each section and function.

 **UI Layout**:
   - Build all UI elements (buttons, inputs, containers, etc.) as described in the instructions.
   - Follow the specified **palette ** for consistent design.

 **Error-Free Code**:
   - Validate that all generated code works without runtime errors or missing functionality.
   - Placeholder comments like "// TODO" or "Add functionality here" must not exist.

 **Fully Tested**:
   - Simulate user interactions to verify every feature works correctly.
   - All button actions, inputs, and ads should function exactly as specified.
`;

    console.log("Generated Prompt:", prompt); // Debugging output

    try {
        // Step 1: Call OpenAI API to get the generated code
        const response = await axios.post('https://api.openai.com/v1/chat/completions', {
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "user",
                    content: prompt,
                }
            ],
        }, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
                'Content-Type': 'application/json',
            },
        });

        console.log("Details response received:", response.data);

        let detailsContent = '';
        if (response.data.choices && response.data.choices.length > 0) {
            detailsContent = response.data.choices[0].message.content;
        } else {
            console.error("No details found in the response.");
        }

        // Return the fully generated code
        return detailsContent;

    } catch (error) {
        logError(error);
        throw error; // Pass the error up for handling
    }
}
