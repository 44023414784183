import React, { useState } from "react";
import Card from "@mui/material/Card";
import { Dialog, DialogTitle, DialogContent, Button, IconButton } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/TableDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import { IoCopy } from "react-icons/io5";
import { logPageExpansion } from "./aiExpanderFunctions"; // Importing function from external file
import { MdClose } from "react-icons/md"; // Import the close icon
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery

function aicodeexpander() {
  const [loading2, setLoading2] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(""); // Content for the popup
  const [pageName, setPageName] = useState(""); // Input: Page name
  const [adType, setAdType] = useState(""); // Selected ad type
  const [pageDescription, setPageDescription] = useState(""); // Input: Page description
  const [fileCode, setFileCode] = useState(""); // Input: File code
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed

  const handleClick = async () => {
    setLoading2(true); // Start loading
    try {
      const result = await logPageExpansion({ pageName, adType, pageDescription, fileCode}); // Call external function
      setPopupContent(result); // Populate popup content
      setShowPopup(true); // Show popup
    } catch (error) {
      console.error("Error in logPageExpansion:", error);
    } finally {
      setLoading2(false); // Stop loading
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(popupContent).then(() => {
      alert("Content copied to clipboard!");
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card style={{ padding: "16px", marginBottom: "16px" }}>
          <VuiTypography variant="lg" color="white">
            AI Code Expander
          </VuiTypography>
        </Card>

        <Card style={{ padding: "16px" }} >
          <VuiTypography  style={{marginBottom: isMobile? "15px":"0px"}} variant="h6" color="white">
            Name of Page
          </VuiTypography>
          <VuiInput
            placeholder="Enter page name"
            fullWidth
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
          />

          <VuiTypography variant="h6" color="white" mt={4}>
            Select Ad Type
          </VuiTypography>
          <VuiBox display="flex" gap={2} mt={2}>
            {["Banner", "Interstitial", "None"].map((type) => (
              <Button
                key={type}
                variant={adType === type ? "contained" : "outlined"}
                onClick={() => setAdType(type)}
                style={{ margin: "10px", color: "white" }}
              >
                {type}
              </Button>
            ))}
          </VuiBox>

          <VuiTypography variant="h6" color="white" style={{marginBottom: isMobile? "15px":"0px"}} mt={4}>
            Changes To Make To File
          </VuiTypography>
          <VuiInput
            placeholder="Enter page description"
            fullWidth
            multiline
            rows={4}
            value={pageDescription}
            onChange={(e) => setPageDescription(e.target.value)}
          />

          <VuiTypography variant="h6" color="white" style={{marginBottom: isMobile? "15px":"0px"}} mt={4}>
            File Code
          </VuiTypography>
          <VuiInput
            placeholder="Enter file code"
            fullWidth
            multiline
            rows={4}
            value={fileCode}
            onChange={(e) => setFileCode(e.target.value)}
          />

          <VuiBox mt="20px">
            <button
              style={{
                width: "100%",
                padding: "12px",
                backgroundColor: "#0175ff",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={handleClick}
              disabled={loading2}
            >
              {loading2 ? "Coding your file, please wait..." : "Start AI Expander"}
            </button>
          </VuiBox>
        </Card>

        {/* Popup Dialog */}
        <Dialog
          open={showPopup}
          onClose={() => setShowPopup(false)}
          maxWidth="lg"
          PaperProps={{
            style: {
              height: "80vh",
              width: isMobile? "100%" : "70vw",
              backgroundColor:'black',
              padding: isMobile? "10px" :'15px'
            },
          }}
        >
          <DialogTitle style={{color:'white'}}>
            This is your expanded file
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setShowPopup(false)}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <MdClose />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <pre
              style={{
                backgroundColor: "#f4f4f4",
                padding: "10px",
                borderRadius: "4px",
                overflowX: "auto",
                height: "calc(100% - 50px)", // Ensure content fits
              }}
            >
              {popupContent}
            </pre>
            <Button
              startIcon={<IoCopy />}
              variant="contained"
              color="primary"
              onClick={handleCopy}
              style={{ marginTop: "10px", color:'white' }}
            >
              Copy
            </Button>
          </DialogContent>
        </Dialog>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default aicodeexpander;
