import axios from "axios";
import { getAuth } from "firebase/auth";

export async function generateCode(appstoreName, prompt, pageCount, dartFileNames) {
    const apiKey = process.env.REACT_APP_API_KEY;
    let detailsContent = "";

    const logError = (error) => {
        if (error.response) {
            console.error("Error response data:", error.response.data);
            console.error("Error response status:", error.response.status);
            console.error("Error response headers:", error.response.headers);
        } else if (error.request) {
            console.error("No response received:", error.request);
        } else {
            console.error("Error setting up request:", error.message);
        }
    };

    console.log("App Store Name:", appstoreName);
    console.log("Prompt:", prompt);
    console.log("Number of Pages:", pageCount);

    try {
        // Step 1: Call OpenAI API to get detailsContent
        const response = await axios.post('https://api.openai.com/v1/chat/completions', {
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "user",
                    content: prompt,
                }
            ],
        }, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
                'Content-Type': 'application/json',
            },
        });

        console.log("Details response received:", response.data);

        if (response.data.choices && response.data.choices.length > 0) {
            detailsContent = response.data.choices[0].message.content;
        } else {
            console.error("No details found in the response.");
        }

       
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          console.error("User is not authenticated.");
          return null;
        }
      
        const userId = user.uid;

        console.log("Payload being sent:", {
            appstoreName: appstoreName,
            detailsContent: detailsContent,
            dartFileNames: dartFileNames,
            userId: userId
        });     
         

        // Step 2: Prepare the data for the backend API
        const data = {
            appstoreName: appstoreName,
            detailsContent: detailsContent,
            dartFileNames: dartFileNames,
            userId: userId

        };

        // Step 3: Send the data to your backend API (no token needed anymore)
        axios
            .post('https://savecodedetailsapi-wkmofyogxa-uc.a.run.app/', data, {
                headers: {
                    'Content-Type': 'application/json',
                    // No Authorization header needed
                },
            })
            .then((response) => {
                console.log("Server Response:", response.data);
                
                // Get the download URL from the response
                const downloadUrl = response.data.downloadUrl;
        
                // Trigger the download
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = '';  // This will trigger an automatic download without specifying a filename (it uses the original file name)
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);  // Clean up after the click
            })
            .catch((error) => {
                logError(error);
            });

    } catch (error) {
        logError(error);
    }

    return detailsContent;
}
